import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React from "react";

type BaseCardProps = {
  children: React.ReactNode;
  cardOverrideStyle?: string;
  disableMargin?: boolean;
  wrapperOverrideStyle?: {};
};

const useStyles = makeStyles((theme: Theme) => ({
  generalInfoWrapper: {
    display: "flex",
    alignItems: "center",

    [theme.breakpoints.down(968)]: {
      width: "100%",
    },
  },
  generaInfoCard: {
    width: "100%",
    display: "flex",
    padding: "28px 28px 32px",
    boxSizing: 'border-box',

    [theme.breakpoints.down("md")]: {
      padding: 16,
      paddingBottom: 0,
    },
  },
  contentWrapper: {
    zIndex: 1000,
    marginTop: 40,
    backgroundColor: "#fff",
    borderRadius: 10,

    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)",

    [theme.breakpoints.down("xl")]: {
      marginTop: 24,
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 14,
    },
  },
  disableMargin: {
    margin: "0 !important",
  },
}));

const BaseCard = ({
  children,
  cardOverrideStyle = "",
  wrapperOverrideStyle = {},
  disableMargin = false,
}: BaseCardProps) => {
  const classes = useStyles();

  return (
    <div
      style={wrapperOverrideStyle}
      className={clsx(
        classes.contentWrapper,
        disableMargin ? classes.disableMargin : ""
      )}
    >
      <div className={classes.generalInfoWrapper}>
        <div className={clsx(classes.generaInfoCard, cardOverrideStyle)}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default BaseCard;
