import React from 'react';

type UnsecureRouteProps = {
  component: any;
};

const UnsecureRoute = ({
  component: Component,
  ...props
}: UnsecureRouteProps) => {
  return <Component {...props} />;
};

export default UnsecureRoute;
