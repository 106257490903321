import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const useAppBreakpoints = () => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down("sm"));
  const xsAndUp = useMediaQuery(theme.breakpoints.up("xs"));
  const smAndDown = useMediaQuery(theme.breakpoints.between("xs", "md"));
  const sm = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const smAndUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdAndDown = useMediaQuery(theme.breakpoints.between("xs", "lg"));
  const md = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const mdAndUp = useMediaQuery(theme.breakpoints.up("md"));
  const lgAndDown = useMediaQuery(theme.breakpoints.between("xs", "xl"));
  const lg = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const lgAndUp = useMediaQuery(theme.breakpoints.up("lg"));

  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  const downXl = useMediaQuery(theme.breakpoints.down("xl"));
  const downMd = useMediaQuery(theme.breakpoints.down("md"));
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  return {
    xs,
    xsAndUp,
    smAndDown,
    sm,
    smAndUp,
    mdAndDown,
    md,
    mdAndUp,
    lgAndDown,
    lg,
    lgAndUp,
    downLg,
    downXl,
    downMd,
    downSm
  };
};

export default useAppBreakpoints;
