import { AxiosError, AxiosResponse } from "axios";

export type DateOfBirth = {
  day: number;
  month: number;
  year: number;
};

const MILLISECONDS_IN_ONE_DAY = 86400000;

type HTTPStatus = number;

/**
 * Return message for HTTP status code
 * @param {number} status - HTTP status code
 * @returns {string} Message of network operation
 */

function _getStatusMessage(status: HTTPStatus) {
  let message: string;
  switch (status) {
    case 200:
      message = "All done. Request successfully executed";
      break;
    case 201:
      message = "Data successfully created";
      break;
    case 400:
      message = "Bad Request";
      break;
    case 401:
      message = "Need auth";
      break;
    case 404:
      message = "Not found";
      break;
    case 503:
      message = "Service Unavailable";
      break;
    default:
      message = "Something wrong. Client default error message";
      break;
  }
  return message;
}

function _getResponseErrorMessage(error: AxiosError) {
  if (error.response && error.response.data)
    return error.response.data.description;
  if (error.response && error.response.statusText)
    return error.response.statusText;
  if (error.message) return error.message;
  return "_getResponseErrorMessage can't handle error";
}

/**
 * Create instant, which represent response object
 * @param {Object} [data] - custom data
 * @param {Object} [response] - axios response object
 * @param {String} [message] - custom message to display
 */
export class ResponseWrapper<T> {
  data: T;
  success: boolean;
  status: number;
  statusMessage: string;
  message: string | null;
  constructor(response: AxiosResponse, data: T, message?: string) {
    this.data = data;
    this.success = response.data.success || true;
    this.status = response.status;
    this.statusMessage = _getStatusMessage(this.status);
    this.message = message || null;
  }
}

/**
 * Create instant, which represent error object
 * @param {Object} [error] - axios error object
 * @param {String} [message] - custom message to display
 */
export class ErrorWrapper extends Error {
  success: any;
  status: number;
  statusMessage: string;
  constructor(error: AxiosError, message?: string) {
    super();
    this.name = "ErrorWrapper";
    this.stack = new Error().stack;
    this.success = error.response ? error.response.data.success : false;
    this.status = error.response ? error.response.status : 0;
    this.statusMessage = _getStatusMessage(this.status);
    this.message = message || _getResponseErrorMessage(error);
  }
}

export function isWithinLastDays(date, days = 1) {
  const lastDays = new Date().getTime() - (days * MILLISECONDS_IN_ONE_DAY);
  
  return lastDays <= new Date(date).getTime();
}

export function isWithinLastMinutesParams(date, minutes) {
  const currentDateMinusMinutes = new Date().getTime() - (minutes * 60000);
  
  return new Date(date).getTime() >= currentDateMinusMinutes;
}

export function isAfterLastMinutesParams(date, minutes) {
  const currentDatePlusMinutes = new Date(date).getTime() + (minutes * 60000);
  
  return new Date().getTime() >= currentDatePlusMinutes;
}

export function fetchDataWithTimeout(url, timeout = 10000, defaultResult = {}) {
  return new Promise((resolve, reject) => {
    const timeoutId = setTimeout(() => {
      reject(new Error('Request timed out'));
    }, timeout);

    fetch(url)
      .then(response => response.json())
      .then(data => {
        clearTimeout(timeoutId);
        resolve(data);
      })
      .catch(error => {
        clearTimeout(timeoutId);
        console.error('Error:', error.message);
        resolve(defaultResult);
      });
  });
}

