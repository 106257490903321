import { useFocus } from "@/hooks";
import useFormikField from "@/hooks/useFormikField";
import {
    FormHelperText,
    Theme
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  base: {
    width: "100%",
    margin: "0 4px",
  },
  inputWrapper: {
    positiong: "relative",
    display: "flex",
    alignItems: "center",
    borderRadius: 50,
    border: "1px solid rgba(0, 0, 0, 0.1)",
    padding: "0 10px",
    fontSize: 14,
    backgroundColor: "#F9F8FD",
    overflow: "hidden",
  },
  input: {
    outline: "none",
    fontFamily: "'Readex Pro', 'Roboto', 'Helvetica', 'Arial', 'sans-serif' ",
    fontWeight: 400,
    flex: "1 1 auto",
    padding: 10,
    border: "none",
    background: "transparent",
    fontSize: 14,
    lineHeight: "20px",
  },
  focused: {
    borderColor: theme.palette.radioSelected.main,
    boxShadow: `rgb(255 255 255) 0px 0px 0px 0px, ${theme.palette.radioSelected.main} 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px`,
  },
}));

type SecondaryTextFieldProps = {
  prepend?: React.ReactNode;
  append?: React.ReactNode;
  readonly?: boolean;
  label: string;
  rootClass?: string;
  onChange: (val: string) => void;
} & React.InputHTMLAttributes<HTMLInputElement>;

const SecondaryTextField = ({
  prepend = null,
  append = null,
  readonly = false,
  rootClass = "",
  label,
  onChange,
  ...props
}: SecondaryTextFieldProps) => {
  const classes = useStyles();
  const [field, meta, errorText] = useFormikField({ label });
  //@ts-ignore
  const { onFocus, onBlur, isFocused } = useFocus(field.onBlur);
  return (
    <div className={clsx(classes.base, rootClass)}>
      <label
        className={clsx(classes.inputWrapper, isFocused && classes.focused)}
      >
        {prepend && prepend}
        <input
          {...props}
          {...field}
          readOnly={readonly}
          className={classes.input}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onChange}
        />
        {append && append}
      </label>
      {errorText && <FormHelperText error={true}>{errorText}</FormHelperText>}
    </div>
  );
};

export default React.memo(SecondaryTextField);
