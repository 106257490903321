import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React from "react";

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#F9F8FD",
    overflow: "hidden",
    width: "100%",
    fontSize: 14,
    borderRadius: 50,
    border: "1px solid rgba(0, 0, 0, 0.1)",
  },
  input: {
    border: "none",
    borderRadius: 50,
    padding: "0 16px",
    lineHeight: "42px",
    fontFamily: "Readex Pro",
    fontSize: 14,
    fontWeight: 500,
    flex: "1 1 auto",
    background: "transparent",
    outline: "none",
  },
}));

type PrimaryBaseTextFieldProps = {
  label: string;
  inputClassName: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

const PrimaryBaseTextField = ({
  label,
  inputClassName,
  ...props
}: PrimaryBaseTextFieldProps) => {
  const classes = useStyles();

  return (
    <div>
      <label className={classes.label}>
        <input
          className={clsx(classes.input, inputClassName)}
          placeholder={label}
          {...props}
        />
      </label>
    </div>
  );
};

export default PrimaryBaseTextField;
