export enum UserPhotoType {
  PRIVATE_VIEW = 'private',
  PUBLIC_VIEW = 'public',
}

export enum UserVideoType {
  PRIVATE_VIEW = 'private',
  PUBLIC_VIEW = 'public',
}

export const GET_USER_PROFILE_REQUEST = 'GET_USER_PROFILE_REQUEST';
export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';
export const GET_USER_PROFILE_FAILURE = 'GET_USER_PROFILE_FAILURE';

export const GET_OWNER_PROFILE_REQUEST = 'GET_OWNER_PROFILE_REQUEST';
export const GET_OWNER_PROFILE_SUCCESS = 'GET_OWNER_PROFILE_SUCCESS';
export const GET_OWNER_PROFILE_FAILURE = 'GET_OWNER_PROFILE_FAILURE';

export const CLEAR_USER_REQUEST = 'CLEAR_USER_REQUEST';
export const CLEAR_USER_SUCCESS = 'CLEAR_USER_SUCCESS';

export const GET_SEARCH_USERS_REQUEST = 'GET_SEARCH_USERS_REQUEST';
export const GET_SEARCH_USERS_SUCCESS = 'GET_SEARCH_USERS_SUCCESS';
export const GET_SEARCH_USERS_FAILURE = 'GET_SEARCH_USERS_FAILURE';

export const UPDATE_CURRENT_USER_PROFILE_REQUEST = 'UPDATE_CURRENT_USER_PROFILE_REQUEST';
export const UPDATE_CURRENT_USER_PROFILE_SUCCESS = 'UPDATE_CURRENT_USER_PROFILE_SUCCESS';
export const UPDATE_CURRENT_USER_PROFILE_FAILURE = 'UPDATE_CURRENT_USER_PROFILE_FAILURE';

export const UPDATE_CURRENT_USER_AVATAR_REQUEST = 'UPDATE_CURRENT_USER_AVATAR_REQUEST';
export const UPDATE_CURRENT_USER_AVATAR_SUCCESS = 'UPDATE_CURRENT_USER_AVATAR_SUCCESS';
export const UPDATE_CURRENT_USER_AVATAR_FAILURE = 'UPDATE_CURRENT_USER_AVATAR_FAILURE';

export const GET_USER_PHOTOS_REQUEST = 'GET_USER_PHOTOS_REQUEST';
export const GET_USER_PHOTOS_SUCCESS = 'GET_USER_PHOTOS_SUCCESS';
export const GET_USER_PHOTOS_FAILURE = 'GET_USER_PHOTOS_FAILURE';

export const GET_PROFILE_PHOTOS_REQUEST = 'GET_PROFILE_PHOTOS_REQUEST';
export const GET_PROFILE_PHOTOS_SUCCESS = 'GET_PROFILE_PHOTOS_SUCCESS';
export const GET_PROFILE_PHOTOS_FAILURE = 'GET_PROFILE_PHOTOS_FAILURE';

export const POST_USER_PHOTOS_REQUEST = 'POST_USER_PHOTOS_REQUEST';
export const POST_USER_PHOTOS_SUCCESS = 'POST_USER_PHOTOS_SUCCESS';
export const POST_USER_PHOTOS_FAILURE = 'POST_USER_PHOTOS_FAILURE';

export const DELETE_USER_PHOTOS_REQUEST = 'DELETE_USER_PHOTOS_REQUEST';
export const DELETE_USER_PHOTOS_SUCCESS = 'DELETE_USER_PHOTOS_SUCCESS';
export const DELETE_USER_PHOTOS_FAILURE = 'DELETE_USER_PHOTOS_FAILURE';

export const GET_USER_VIDEOS_REQUEST = 'GET_USER_VIDEOS_REQUEST';
export const GET_USER_VIDEOS_SUCCESS = 'GET_USER_VIDEOS_SUCCESS';
export const GET_USER_VIDEOS_FAILURE = 'GET_USER_VIDEOS_FAILURE';

export const GET_PROFILE_VIDEOS_REQUEST = 'GET_PROFILE_VIDEOS_REQUEST';
export const GET_PROFILE_VIDEOS_SUCCESS = 'GET_PROFILE_VIDEOS_SUCCESS';
export const GET_PROFILE_VIDEOS_FAILURE = 'GET_PROFILE_VIDEOS_FAILURE';

export const POST_USER_VIDEOS_REQUEST = 'POST_USER_VIDEOS_REQUEST';
export const POST_USER_VIDEOS_SUCCESS = 'POST_USER_VIDEOS_SUCCESS';
export const POST_USER_VIDEOS_FAILURE = 'POST_USER_VIDEOS_FAILURE';

export const DELETE_USER_VIDEOS_REQUEST = 'DELETE_USER_VIDEOS_REQUEST';
export const DELETE_USER_VIDEOS_SUCCESS = 'DELETE_USER_VIDEOS_SUCCESS';
export const DELETE_USER_VIDEOS_FAILURE = 'DELETE_USER_VIDEOS_FAILURE';

export const GET_AVAILABLE_USERS_REQUEST = 'GET_AVAILABLE_USERS_REQUEST';
export const GET_AVAILABLE_USERS_SUCCESS = 'GET_AVAILABLE_USERS_SUCCESS';
export const GET_AVAILABLE_USERS_FAILURE = 'GET_AVAILABLE_USERS_FAILURE';

export const GET_USERS_DIALOGS_REQUEST = 'GET_USERS_DIALOGS_REQUEST';
export const GET_USERS_DIALOGS_SUCCESS = 'GET_USERS_DIALOGS_SUCCESS';
export const GET_USERS_DIALOGS_FAILURE = 'GET_USERS_DIALOGS_FAILURE';

export const ADD_USERS_DIALOGS_SUCCESS = 'ADD_USERS_DIALOGS_SUCCESS';

export const READ_USERS_DIALOGS_REQUEST = 'READ_USERS_DIALOGS_REQUEST';

export const GET_DIALOG_COUNTERS = 'GET_DIALOG_COUNTERS';
export const GET_DIALOG_COUNTERS_SUCCESS = 'GET_DIALOG_COUNTERS_SUCCESS';
export const SOCKET_UPDATE_DIALOG_COUNT = 'SOCKET_UPDATE_DIALOG_COUNT';

export const READ_MESSAGE = 'READ_MESSAGE';

//SOCKETS MESSAGE

export const SEND_MESSAGE_TO_CHAT_REQUEST = 'SEND_MESSAGE_TO_CHAT_REQUEST';
export const SEND_MESSAGE_TO_CHAT_SUCCESS = 'SEND_MESSAGE_TO_CHAT_SUCCESS';

export const SEND_PHOTO_MESSAGE_TO_CHAT_REQUEST = 'SEND_PHOTO_MESSAGE_TO_CHAT_REQUEST';
export const SEND_PHOTO_MESSAGE_TO_CHAT_SUCCESS = 'SEND_PHOTO_MESSAGE_TO_CHAT_SUCCESS';

export const SEND_VIDEO_MESSAGE_TO_CHAT_REQUEST = 'SEND_VIDEO_MESSAGE_TO_CHAT_REQUEST';
export const SEND_VIDEO_MESSAGE_TO_CHAT_SUCCESS = 'SEND_VIDEO_MESSAGE_TO_CHAT_SUCCESS';

export const RECEIVE_MESSAGE_TO_CHAT_SUCCESS = 'RECEIVE_MESSAGE_TO_CHAT_SUCCESS';
export const RECEIVE_MESSAGE_TO_CHAT_REQUEST = 'RECEIVE_MESSAGE_TO_CHAT_SUCCESS';

export const REVEAL_PHOTO_CHAT_SUCCESS = 'REVEAL_PHOTO_CHAT_SUCCESS';
export const REVEAL_PHOTO_CHAT_REQUEST = 'REVEAL_PHOTO_CHAT_REQUEST';

export const RECEIVE_PHOTO_MESSAGE_TO_CHAT_SUCCESS = 'RECEIVE_PHOTO_MESSAGE_TO_CHAT_SUCCESS';

export const REVEAL_VIDEO_CHAT_SUCCESS = 'REVEAL_VIDEO_CHAT_SUCCESS';
export const REVEAL_VIDEO_CHAT_REQUEST = 'REVEAL_VIDEO_CHAT_REQUEST';

export const RECEIVE_VIDEO_MESSAGE_TO_CHAT_SUCCESS = 'RECEIVE_VIDEO_MESSAGE_TO_CHAT_SUCCESS';

export const SET_VIDEO_CONVERTED = 'SET_VIDEO_CONVERTED';

export const TRANSLATE_MESSAGE_TO_CHAT_SUCCESS = 'TRANSLATE_MESSAGE_TO_CHAT_SUCCESS';

export const SET_BOOKMARKS_REQUEST = 'SET_BOOKMARKS_REQUEST';
export const SET_BOOKMARKS_SUCCESS = 'SET_BOOKMARKS_SUCCESS';
export const SET_BOOKMARKS_FAILURE = 'SET_BOOKMARKS_FAILURE';

export const GET_BOOKMARKS_REQUEST = 'GET_BOOKMARKS_REQUEST';
export const GET_BOOKMARKS_SUCCESS = 'GET_BOOKMARKS_SUCCESS';
export const GET_BOOKMARKS_FAILURE = 'GET_BOOKMARKS_FAILURE';

export const DELETE_BOOKMARKS_REQUEST = 'DELETE_BOOKMARKS_REQUEST';
export const DELETE_BOOKMARKS_SUCCESS = 'DELETE_BOOKMARKS_SUCCESS';
export const DELETE_BOOKMARKS_FAILURE = 'DELETE_BOOKMARKS_FAILURE';

export const UPDATE_PIN_SUCCESS = 'UPDATE_PIN_SUCCESS';

export const SET_UPDATE_AVAILABLE_USERS = 'SET_UPDATE_AVAILABLE_USERS';
export const SET_DISCONNECTED_AVAILABLE_USERS = 'SET_DISCONNECTED_AVAILABLE_USERS';
export const REMOVE_INVITE = 'REMOVE_INVITE';

export const ADD_VIDEO_INVITE = 'ADD_VIDEO_INVITE';
export const REMOVE_VIDEO_INVITE = 'REMOVE_VIDEO_INVITE';
export const DISCONNECT_CHAT = 'DISCONNECT_CHAT';

export const UPDATE_BALANCE_REQUEST = 'UPDATE_BALANCE_REQUEST';
export const UPDATE_BALANCE_SUCCESS = 'UPDATE_BALANCE_SUCCESS';
export const UPDATE_BALANCE_FAILED = 'UPDATE_BALANCE_FAILED';

export const UPDATE_INAPP_REQUEST = 'UPDATE_INAPP_REQUEST';
export const UPDATE_INAPP_SUCCESS = 'UPDATE_INAPP_SUCCESS';
export const UPDATE_INAPP_FAILED = 'UPDATE_INAPP_FAILED';

export const FILTER_INAPP_REQUEST = 'FILTER_INAPP_REQUEST';
export const FILTER_INAPP_SUCCESS = 'FILTER_INAPP_SUCCESS';
export const FILTER_INAPP_FAILED = 'FILTER_INAPP_FAILED';

export const UPDATE_QUESTIONS_REQUEST = 'UPDATE_QUESTIONS_REQUEST';
export const UPDATE_QUESTIONS_SUCCESS = 'UPDATE_QUESTIONS_SUCCESS';
export const UPDATE_QUESTIONS_FAILED = 'UPDATE_QUESTIONS_FAILED';

export const FILTER_QUESTIONS_REQUEST = 'FILTER_QUESTIONS_REQUEST';
export const FILTER_QUESTIONS_SUCCESS = 'FILTER_QUESTIONS_SUCCESS';
export const FILTER_QUESTIONS_FAILED = 'FILTER_QUESTIONS_FAILED';

export const CLOSE_QUESTIONS_REQUEST = 'CLOSE_QUESTIONS_REQUEST';
export const CLOSE_QUESTIONS_SUCCESS = 'CLOSE_QUESTIONS_SUCCESS';
export const CLOSE_QUESTIONS_FAILED = 'CLOSE_QUESTIONS_FAILED';

export const CLOSE_QUESTIONS_FOR_DAYS_REQUEST = 'CLOSE_QUESTIONS_FOR_DAYS_REQUEST';
export const CLOSE_QUESTIONS_FOR_DAYS_SUCCESS = 'CLOSE_QUESTIONS_FOR_DAYS_SUCCESS';
export const CLOSE_QUESTIONS_FOR_DAYS_FAILED = 'CLOSE_QUESTIONS_FOR_DAYS_FAILED';

export const CHARGE_BALANCE_REQUEST = 'CHARGE_BALANCE_REQUEST';
export const CHARGE_BALANCE_SUCCESS = 'CHARGE_BALANCE_SUCCESS';
export const CHARGE_BALANCE_FAILED = 'CHARGE_BALANCE_FAILED';

export const GET_SETTINGS_REQUEST = 'GET_SETTINGS_REQUEST';
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
export const GET_SETTINGS_FAILED = 'GET_SETTINGS_FAILED';

export const GET_EMAIL_EVENTS_BLACKLIST_REQUEST = 'GET_EMAIL_EVENTS_BLACKLIST_REQUEST';
export const GET_EMAIL_EVENTS_BLACKLIST_SUCCESS = 'GET_EMAIL_EVENTS_BLACKLIST_SUCCESS';
export const GET_EMAIL_EVENTS_BLACKLIST_FAILED = 'GET_EMAIL_EVENTS_BLACKLIST_FAILED';

export const GET_ACQUIRING_REQUEST = 'GET_ACQUIRING_REQUEST';
export const GET_ACQUIRING_SUCCESS = 'GET_ACQUIRING_SUCCESS';
export const GET_ACQUIRING_FAILED = 'GET_ACQUIRING_FAILED';

export const GET_CREDIT_PACKS_REQUEST = 'GET_CREDIT_PACKS_REQUEST';
export const GET_CREDIT_PACKS_SUCCESS = 'GET_CREDIT_PACKS_SUCCESS';
export const GET_CREDIT_PACKS_FAILED = 'GET_CREDIT_PACKS_FAILED';

export const USER_BALANCE_CHARGED_SUCCESS = 'USER_BALANCE_CHARGED_SUCCESS';
export const SET_VIDEO_CALL = 'SET_VIDEO_CALL';

export const SET_USER_HEADER_SEARCH = 'SET_USER_HEADER_SEARCH';

export const CHANGE_USER_PASSWORD = 'CHANGE_USER_PASSWORD';
export const CHANGE_USER_EMAIL = 'CHANGE_USER_EMAIL';
export const CHANGE_USER_EMAIL_SUCCESS = 'CHANGE_USER_EMAIL_SUCCESS';

export const CLEAR_AVAILABLE_USERS_SUCCESS = 'CLEAR_AVAILABLE_USERS_SUCCESS';

export const SET_BLOCKED_BY_ME_REQUEST = 'SET_BLOCKED_BY_ME_REQUEST';
export const SET_BLOCKED_BY_ME_SUCCESS = 'SET_BLOCKED_BY_ME_SUCCESS';
export const SET_BLOCKED_BY_ME_FAILURE = 'SET_BLOCKED_BY_ME_FAILURE';

export const DELETE_BLOCKED_BY_ME_REQUEST = 'DELETE_BLOCKED_BY_ME_REQUEST';
export const DELETE_BLOCKED_BY_ME_SUCCESS = 'DELETE_BLOCKED_BY_ME_SUCCESS';
export const DELETE_BLOCKED_BY_ME_FAILURE = 'DELETE_BLOCKED_BY_ME_FAILURE';

export const GET_BLOCKED_USERS_REQUEST = 'GET_BLOCKED_USERS_REQUEST';
export const GET_BLOCKED_USERS_SUCCESS = 'GET_BLOCKED_USERS_SUCCESS';
export const GET_BLOCKED_USERS_FAILURE = 'GET_BLOCKED_USERS_FAILURE';
