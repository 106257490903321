import { ColorPalatte } from "@/types/color.types";
import {
  Button,
  ButtonProps,
  CircularProgress,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React, { forwardRef } from "react";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    fontSize: 16,
    // flex: "1 1 auto",
  },
  root: {
    padding: "10px 10px",
  },
}));

type ButtonBaseProps = {
  Icon?: React.ReactNode | null;
  inverted?: boolean;
  loading?: boolean;
  textStyle?: string | undefined;
  text: string;
  wrapperClass?: string;
  color?: ColorPalatte;
} & ButtonProps;

const BaseButton = forwardRef<any, ButtonBaseProps>(function (
  {
    Icon = null,
    text,
    wrapperClass = "",
    loading = false,
    color = "primary",
    inverted = false,
    textStyle = undefined,
    className,
    ...props
  },
  ref
) {
  const classes = useStyles();

  const content = loading ? (
    <CircularProgress size={18} color="inherit" />
  ) : (
    <Typography
      variant={inverted ? "subtitle1" : "button"}
      className={textStyle}
    >
      {text}
    </Typography>
  );

  return (
    <Button
      disabled={loading}
      ref={ref}
      classes={{
        root: clsx(classes.root, wrapperClass),
      }}
      disableElevation={inverted}
      disableRipple
      color={inverted ? "info" : color}
      className={clsx(classes.wrapper, className)}
      variant="contained"
      startIcon={Icon}
      {...props}
    >
      {content}
    </Button>
  );
});

export default BaseButton;
