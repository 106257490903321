import { AnyAction } from "redux";
import { GET_USER_PROFILE_SUCCESS } from "../users/users.types";
import {
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
  SIGN_UP_FAILURE,
  SIGN_OUT_REQUEST,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_FAILURE,
  GET_ME_REQUEST,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
} from "./auth.types";

const initialState = {
  authIsLoading: false,
  signedIn: false,
  signedUp: false,
  userId: null,
  error: null,
};

const auth = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SIGN_IN_REQUEST:
    case GET_ME_REQUEST:
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        authIsLoading: true,
      };

    case RESET_PASSWORD_SUCCESS:
    case RESET_PASSWORD_FAILURE: {
      return {
        ...state,
        authIsLoading: false,
      };
    }

    case SIGN_IN_SUCCESS:
    case GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        authIsLoading: false,
        signedIn: true,
        userId: action.payload,
      };
    case SIGN_IN_FAILURE:
      return {
        ...state,
        authIsLoading: false,
      };
    case SIGN_OUT_REQUEST:
      return {
        ...state,
        authIsLoading: true,
      };
    case SIGN_OUT_SUCCESS:
      return {
        ...state,
        authIsLoading: false,
        signedIn: false,
        signedUp: false,
      };
    case SIGN_OUT_FAILURE:
      return {
        ...state,
        ...state,
        authIsLoading: false,
        signedIn: false,
        signedUp: false,
        error: action.error,
      };
    case SIGN_UP_REQUEST:
      return {
        ...state,
        signedUp: false,
        authIsLoading: true,
      };
    case SIGN_UP_SUCCESS:
      return {
        ...state,
        signedUp: true,
        authIsLoading: false,
        userId: action.payload,
      };
    case SIGN_UP_FAILURE:
      return {
        ...state,
        signedUp: false,
        authIsLoading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default auth;
