import { format, isSameYear } from "date-fns";

export const isToday = (date: string | Date): boolean => {
  const currentDate = new Date();
  const dateToCompare = new Date(date);

  return (
    currentDate.setHours(0, 0, 0, 0) === dateToCompare.setHours(0, 0, 0, 0)
  );
};

export const getTimeAMPMFormat = (
  date: string | Date | number,
  timeFormatter = "hh:mm"
): string => {
  return format(new Date(date), `${timeFormatter} a`);
};

export const getUTCDate = (date: string | Date | null): number | null => {
  const localDate = new Date(date);
  return localDate.setMinutes(
    localDate.getMinutes() + localDate.getTimezoneOffset()
  );
};

export const formatDateUTC = (date: string | Date | null): string | null => {
  const utcDate = getUTCDate(date);
  return date ? format(utcDate, "MM/dd/yyyy") : null;
};

export const formatDateTimeUTC = (date: string | Date | null): {time: string; date: string} | null => {
  const utcDate = getUTCDate(date);
  return date ? {time: format(utcDate, "HH:mm:ss"), date: format(utcDate, "MM/dd/yyyy")} : null;
};

export const getMMDDFormat = (date: string | Date | null): string | null =>
  date ? format(new Date(date), "MM/dd") : null;

export const formatCreatedAtDate = (date: Date): string => {
  if (isToday(date)) return getTimeAMPMFormat(date);
  if (isSameYear(new Date(), date)) return getMMDDFormat(date);
  return formatDateUTC(date);
};
