import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { changeUserEmailRequest } from '@/app/users/users.actions';
import BaseButton from '@/components/base/button.component';
import SecondaryTextField from '@/components/base/secondary-text-field.component';
import { emailUserSchema } from '@/utils/validationSchemas';
import { FormControl, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Formik } from 'formik';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    width: '100%',
  },
  formControl: {
    '& > $inputField:not(:first-child)': {
      marginTop: 20,
    },
  },
  inputField: {},
  inputAppendElement: {
    flexShrink: 0,
    padding: '0 6px',
    minWidth: 118,

    [theme.breakpoints.down('md')]: {
      minWidth: 80,
    },
  },
  inputFieldShort: {
    maxWidth: '50% ',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100% ',
    },
  },
  saveButton: {
    width: '100%',
    maxWidth: 144,
    marginTop: 32,
  },
  currentEmail: {
    fontWeight: '500',
    color: '#000',
  },
  currentEmailWrapper: {
    marginLeft: 4,
  },
}));
const EmailChangeInfo = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const currentUserEmail = useAppSelector((state) => state.users?.currentUser?.email);

  const initialData = {};

  return (
    <Formik
      initialValues={initialData}
      validateOnChange={false}
      validationSchema={emailUserSchema}
      onSubmit={(values: { email: string }, { setSubmitting }) => {
        dispatch(changeUserEmailRequest(values.email));
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
        <form onSubmit={handleSubmit} className={classes.formContainer}>
          <FormControl fullWidth className={classes.formControl}>
            <div className={classes.currentEmailWrapper}>
              <span className={classes.currentEmail}>Your email: </span>
              {currentUserEmail}
            </div>

            <div className={classes.inputField}>
              <SecondaryTextField
                label='email'
                placeholder='New Email'
                prepend={
                  <Typography variant='body1' fontWeight='400' className={classes.inputAppendElement}>
                    New Email:
                  </Typography>
                }
                onChange={handleChange}
                type='email'
              />
            </div>

            <div style={{ marginTop: 32 }}>
              <BaseButton color='primary' text='Save' className={classes.saveButton} onClick={() => handleSubmit()} />
            </div>
          </FormControl>
        </form>
      )}
    </Formik>
  );
};

export default EmailChangeInfo;
