import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React, { useCallback, useState } from "react";
import { ReactComponent as SearchIcon } from "@/assets/icons/search.svg";
import { setHeaderUserSearch } from "@/app/users/users.actions";
import { useAppDispatch } from "@/app/hooks";
import { useNavigate } from "react-router-dom";


const useStyles = makeStyles((theme: Theme) => ({
  label: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#F9F8FD",
    overflow: "hidden",
    width: "100%",
    fontSize: 14,
    borderRadius: 50,
    border: "1px solid rgba(0, 0, 0, 0.1)",
  },
  input: {
    border: "none",
    borderRadius: 50,
    padding: "0 16px",
    lineHeight: "42px",
    fontFamily: "Readex Pro",
    fontSize: 14,
    fontWeight: 500,
    flex: "1 1 auto",
    background: "transparent",
    outline: "none",
  },
  icon: {
    position: 'absolute',
    right: 13,
    cursor: 'pointer'
  }
}));

type PrimaryBaseTextFieldProps = {
  label: string;
  inputClassName: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

const SearchTextField = ({
  label,
  inputClassName,
  ...props
}: PrimaryBaseTextFieldProps) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();


  const [id, setId] = useState('');

  const searchUser = useCallback(() => {
    dispatch(setHeaderUserSearch(id));

    navigate(`/user/search`);
  },[dispatch, id, navigate])

  return (
    <div>
      <label className={classes.label}>
        <input
          className={clsx(classes.input, inputClassName)}
          placeholder={label}
          {...props}
          value={id}
          onChange={(e) => setId(e.target.value)}
        />
        <SearchIcon className={classes.icon} onClick={searchUser}/>
      </label>
    </div>
  );
};

export default SearchTextField;
