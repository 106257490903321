import { signUpRequest } from '@/app/auth/auth.actions';
import { useAppDispatch } from '@/app/hooks';
import { SignupDto } from '@/services/auth.service';
import { Dialog, DialogProps, Theme, Zoom } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useCallback } from 'react';
import RegistrationLayout from '../layouts/registration.layout';
import './dialog.css';
import { useNavigate } from 'react-router-dom';
import { UserProfile } from '@/types/user-profile.types';
import { CloseIconStyled } from './sign-up-dialog.component';
import ViewProfileLayout from '../layouts/view-profile.layout';
import { fetchDataWithTimeout } from '@/services/utils';

type DialogRegistrationProps = {
  handleViewProfile: () => void;
  open: boolean;
  closeHandler: () => void;
  referenceProfile: UserProfile;
  isRegistration: boolean;
  metaActions: {
    icon: JSX.Element;
    onClick: (data: { id: string; name: string; avatar: string }) => void;
  }[];
} & DialogProps;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '28px 24px',
    overflowY: 'auto',

    [theme.breakpoints.down('sm')]: {
      padding: '28px 12px',
    },
  },
  paper: {
    padding: 0,
    width: 'calc(100% - 20px)!important',
    maxWidth: '1200px!important',
    [theme.breakpoints.down('sm')]: {
      width: '100%!important',
    },
  },
  dialogPaper: {
    overflowY: 'visible',
  },
}));

const DialogRegistration = ({
  open,
  closeHandler,
  referenceProfile,
  handleViewProfile,
  isRegistration,
  metaActions,
}: DialogRegistrationProps) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (values: SignupDto) => {
    let affiliate = {};
    //@ts-ignore
    if (window?.PostAffTracker) {
      //@ts-ignore
      const affTracker = await window?.PostAffTracker?.getAffInfo();
      //@ts-ignore
      const papAccountId = window?.PostAffTracker?._getAccountId() || 'wedaf';
      //@ts-ignore
      const papVisitorId = window?.PostAffTracker?._cmanager?.getVisitorId();

      const papAAid = affTracker?.getAffiliateId() || (window as any)?.localStorage?.getItem('a_aid');
      const papACid = affTracker?.getCampaignId() || (window as any)?.localStorage?.getItem('a_cid');

      const data1 = (window as any)?.localStorage?.getItem('data1');
      const data2 = (window as any)?.localStorage?.getItem('data2');
      const papABid = (window as any)?.localStorage?.getItem('a_bid');

      const source = (window as any)?.localStorage?.getItem('source');
      const clickid = (window as any)?.localStorage?.getItem('clickid');

      if (source === 'affise' && clickid) {
        affiliate = {
          cpaName: 'affise',
          affiseClickId: clickid,
        };
      } else if (papAccountId && papVisitorId && papAAid) {
        const data1Obj = data1 ? { papData1: data1 } : {};
        const data2Obj = data2 ? { papData2: data2 } : {};
        const papABidObj = papABid ? { papABid } : {};
        const papACidObj = papACid ? { papACid } : {};

        affiliate = {
          cpaName: 'postaffiliatepro',
          papAccountId,
          papVisitorId,
          papAAid,
          ...papACidObj,
          ...data1Obj,
          ...data2Obj,
          ...papABidObj,
        };
      }
    }
    dispatch(signUpRequest({ ...values, ...affiliate, ip: await getIP(), navigate, closeHandler }));
  };

  const getIP = useCallback(async () => {
    await fetchDataWithTimeout('https://api.ipify.org?format=json', 3000, { body: '0.0.0.0' })
      .then(async (result) => {
        return result;
      })
      .catch((error) => {
        console.error('Error:', error.body);
        return '0.0.0.0';
      });
    return '0.0.0.0';
  }, []);

  const handleViewRegistration = useCallback(() => {}, []);

  return (
    <Dialog
      classes={{
        root: classes.dialogPaper,
        paper: classes.paper,
      }}
      TransitionComponent={Zoom}
      open={open}
      onClose={() => closeHandler()}
    >
      <div className={classes.container}>
        <CloseIconStyled onClick={closeHandler} />
        {isRegistration ? (
          <RegistrationLayout onSubmit={handleSubmit} referenceProfile={referenceProfile} closeHandler={closeHandler} />
        ) : (
          <ViewProfileLayout
            referenceProfile={referenceProfile}
            handleViewRegistration={handleViewRegistration}
            handleViewProfile={handleViewProfile}
            metaActions={metaActions}
          />
        )}
      </div>
    </Dialog>
  );
};

export default DialogRegistration;
