import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  contentWrapper: {
    zIndex: 1000,
    marginTop: 40,
    [theme.breakpoints.down("xl")]: {
      marginTop: 24,
    },
    [theme.breakpoints.down("md")]: {
      marginTop: 14,
    },
  },
}));

const stylesOverrides = {
  contentWrapper: {
    padding: 0,
    maxWidth: 968,
  },
};
const useContainerStyle = () => {
  const classes = useStyles();

  return {
    wrapperClass: classes.contentWrapper,
    wrapperOverride: stylesOverrides.contentWrapper,
  };
};

export default useContainerStyle;
