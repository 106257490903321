import { AuthDto, ChangePasswordDTO, ResetPasswordDTO, SignupDto } from "@/services/auth.service";
import { NavigateFunction } from "react-router-dom";
import {
  GET_ME_REQUEST,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_REQUEST,
  CHANGE_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_OUT_FAILURE,
  SIGN_OUT_REQUEST,
  SIGN_OUT_SUCCESS,
  SIGN_UP_FAILURE,
  SIGN_UP_REQUEST,
  SIGN_UP_SUCCESS,
} from "./auth.types";

export const signUpRequest = (
  payload: SignupDto & { navigate: NavigateFunction }
) => ({
  type: SIGN_UP_REQUEST,
  payload,
});

export const signUpSuccess = (payload: string) => ({
  type: SIGN_UP_SUCCESS,
  payload,
});

export const signUpFailure = (error: unknown) => ({
  type: SIGN_UP_FAILURE,
  error,
});

export const signInRequest = (
  payload: AuthDto & { navigate: NavigateFunction }
) => ({
  type: SIGN_IN_REQUEST,
  payload,
});

export const resetPasswordRequest = (
  payload: ResetPasswordDTO & { navigate: NavigateFunction }
) => ({
  type: RESET_PASSWORD_REQUEST,
  payload,
});

export const changePasswordRequest = (
  payload: ChangePasswordDTO & { navigate: NavigateFunction }
) => ({
  type: CHANGE_PASSWORD_REQUEST,
  payload,
});

export const resetPasswordSuccess = () => ({
  type: RESET_PASSWORD_SUCCESS,
});

export const resetPasswordFailure = (error: unknown) => ({
  type: RESET_PASSWORD_FAILURE,
  error,
});

export const signInSuccess = (payload: string) => ({
  type: SIGN_IN_SUCCESS,
  payload,
});

export const signInFailure = (error: unknown) => ({
  type: SIGN_IN_FAILURE,
  error,
});

export const getMeRequest = () => ({
  type: GET_ME_REQUEST,
});

export const signOutRequest = (payload: { navigate: NavigateFunction }) => ({
  type: SIGN_OUT_REQUEST,
  payload,
});

export const signOutSuccess = () => ({
  type: SIGN_OUT_SUCCESS,
});

export const signOutFailure = (error: unknown) => ({
  type: SIGN_OUT_FAILURE,
  error,
});
