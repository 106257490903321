import { useAppSelector } from '@/app/hooks';
import { ISettings } from '@/types/user-profile.types';
import React, { useEffect } from 'react';

const LiveChatWidget = () => {
  const { liveChat } = useAppSelector<ISettings>((state) => state.users.settings);

  useEffect(() => {
    if (liveChat) {
      (window as any).__lc = (window as any).__lc || {};
      (window as any).__lc.license = 18537357;
      (window as any).__lc.integration_name = 'manual_onboarding';
      (window as any).__lc.product_name = 'livechat';
      if((window as any).LiveChatWidget) {
        (window as any).LiveChatWidget.call('hide');
      }
      const script = document.createElement('script');
      script.src = 'https://cdn.livechatinc.com/tracking.js';
      script.async = true;
      script.onload = () => {
        if (typeof (window as any).__lc === 'object') {
          (function (n, t, c) {
            function i(n) {
              return e._h ? e._h.apply(null, n) : e._q.push(n);
            }
            var e = {
              _q: [],
              _h: null,
              _v: '2.0',
              on: function () {
                i(['on', c.call(arguments)]);
              },
              once: function () {
                i(['once', c.call(arguments)]);
              },
              off: function () {
                i(['off', c.call(arguments)]);
              },
              get: function () {
                if (!e._h) throw new Error("[LiveChatWidget] You can't use getters before load.");
                return i(['get', c.call(arguments)]);
              },
              call: function () {
                i(['call', c.call(arguments)]);
              },
              init: function () {
                const n = t.createElement('script');
                n.async = !0;
                n.type = 'text/javascript';
                n.src = 'https://cdn.livechatinc.com/chat-widget.js';
                t.head.appendChild(n);
              },
            };
            !(n.__lc && n.__lc.asyncInit) && e.init();
            n.LiveChatWidget = n.LiveChatWidget || e;

            function onVisibilityChanged(data) {
              switch (data.visibility) {
                case "maximized":
                  break;
                case "minimized":
                  n.LiveChatWidget.call('hide');
                  break;
                case "hidden":
                  break;
              }
            }
            
            n.LiveChatWidget.on('visibility_changed', onVisibilityChanged);
            
            n.LiveChatWidget.call('hide');
          })(window as any, document, [].slice);
        }
      };
      document.body.appendChild(script);
    }
  }, [liveChat]);

  return (
    <div style={{ display: 'none' }} className='live-chat-container'>
      {/* Here is LiveChat */}
    </div>
  );
};

export default LiveChatWidget;
