import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

type StatusOnlineProps = {
  small?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  // "p-1 h-5 bg-secondary-50 rounded-full text-black inline-flex inline-flex justify-center text-xs leading-none space-x-1
  wrapper: {
    padding: 4,
    marginTop: 6,
    borderRadius: 20,
    fontSize: 12,

    color: "#000",
    display: "inline-flex",
    justifyContent: "center",
    backgroundColor: theme.palette.info.main,
    lineHeight: 1,
  },
  icon: {
    width: 12,
    height: 12,
    backgroundColor: theme.palette.success.main,
    borderRadius: 9999,
  },
}));

const StatusOnline = ({ small = false }: StatusOnlineProps) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <span className={classes.icon} />
      {!small && <span style={{ marginLeft: 4 }}>online</span>}
    </div>
  );
};

export default StatusOnline;
