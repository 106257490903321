import React from "react";

const useFocus = (handleBlur: any, handleFocus?: any) => {
  const [isFocused, setIsFocused] = React.useState(false);
  const onFocus = React.useCallback(
    (event) => {
      setIsFocused(true);
      handleFocus && handleFocus(event);
    },
    [handleFocus]
  );
  const onBlur = React.useCallback(
    (event) => {
      setIsFocused(false);
      handleBlur && handleBlur(event);
    },
    [handleBlur]
  );

  return { isFocused, onFocus, onBlur };
};

export default useFocus;
