import { useFocus, useFormikField } from "@/hooks";
import { FormHelperText, TextareaAutosize, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React from "react";

type BaseTextareaProps = {
  onChange: (e: React.ChangeEvent<any>) => void;
  label: string;
  className?: string;
  placeholder?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  textarea: {
    width: "calc(100% - 25px)",
    flex: "1 1 auto",
    resize: "none",
    padding: 10,
    border: "1px solid rgba(0, 0, 0, 0.1)",
    fontFamily: "Readex Pro",
    fontSize: 14,
    borderRadius: 10,
    backgroundColor: "#F9F8FD",
  },
  focused: {
    borderColor: theme.palette.radioSelected.main,
    boxShadow: `rgb(255 255 255) 0px 0px 0px 0px, ${theme.palette.radioSelected.main} 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px`,
    outlineColor: theme.palette.radioSelected.main,
  },
}));

const BaseTextarea = ({
  onChange,
  label,
  className = "",
  placeholder = "",
}: BaseTextareaProps) => {
  const classes = useStyles();
  const [field, meta, errorText] = useFormikField({ label });
  const { onFocus, onBlur, isFocused } = useFocus(field.onBlur);

  return (
    <>
      <TextareaAutosize
        maxRows={5}
        onFocus={onFocus}
        onBlur={onBlur}
        value={field.value}
        placeholder={placeholder}
        spellCheck={false}
        name={label}
        onChange={onChange}
        className={clsx(
          classes.textarea,
          isFocused && classes.focused,
          className
        )}
      />
      {errorText && <FormHelperText error={true}>{errorText}</FormHelperText>}
    </>
  );
};

export default BaseTextarea;
