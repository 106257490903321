// import { useAuthStore } from "../store/useAuthStore";
import { UserProfile } from '@/types/user-profile.types';
import { HttpService } from './http.service';
import { StorageService } from './storage.service';
import { ErrorWrapper, ResponseWrapper } from './utils';

//TODO: replace all store action calls with redux-saga handler

export interface AuthDto {
  email: string;
  password: string;
  closeHandler?: () => void;
}

export interface SignupDto extends AuthDto {
  name: string;
  gender: string;
  referralProfileId?: string;
  cpaName?: string;
  papAccountId?: string;
  papVisitorId?: string;
  papAAid?: string;
  papABid?: string;
  papACid?: string;
  papData1?: string;
  papData2?: string;
  affiseClickId?: string;
  ip?: string;
}

export interface LoginResponse {
  accessToken: string;
}

export interface ResetPasswordDTO {
  email: string;
}

export interface ChangePasswordDTO {
  code: string;
  password: string;
}

export default class AuthService {
  static get entity(): string {
    return 'auth';
  }

  static async me(): Promise<ResponseWrapper<UserProfile>> {
    // const $store = useAuthStore();
    try {
      const response = await HttpService.get(`/${this.entity}/me`);

      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async login(data: AuthDto): Promise<LoginResponse> {
    try {
      const response = await HttpService.post(`/${this.entity}/login`, data);
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.message : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async signup(data: SignupDto): Promise<LoginResponse> {
    try {
      const response = await HttpService.post(`${this.entity}/signup`, data);
      StorageService.setToken(response.data.accessToken);
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.message ? error.response.data.message : error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async resetPassword(email: string): Promise<ResetPasswordDTO> {
    try {
      const response = await HttpService.post(`${this.entity}/forgot`, {
        email,
      });
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async changePassword(code: string, password: string): Promise<ResetPasswordDTO> {
    try {
      const response = await HttpService.post(`${this.entity}/change-password`, {
        code,
        password,
      });
      return response.data;
    } catch (error: any) {
      const message = error.response.data ? error.response.data.error : error.response.statusText;
      throw new ErrorWrapper(error, message);
    }
  }

  static async logout(): Promise<void> {
    // const $store = useAuthStore();
    StorageService.removeToken();
    // await $store.$reset();
    return Promise.resolve();
  }
}
