import { signUpRequest } from '@/app/auth/auth.actions';
import { useAppDispatch } from '@/app/hooks';
import { SignupDto } from '@/services/auth.service';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogProps, IconButton, IconButtonProps, Theme, Zoom } from '@mui/material';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useEffect, useState } from 'react';
import SignUpLayout from '../layouts/sign-up.layout';
import './dialog.css';
import { useNavigate } from 'react-router-dom';
import { fetchDataWithTimeout } from '@/services/utils';

type DialogSignUpProps = {
  open: boolean;
  closeHandler: () => void;
} & DialogProps;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: '28px 24px',
    overflowY: 'auto',

    [theme.breakpoints.down('sm')]: {
      padding: '28px 12px',
    },
  },
  paper: {
    padding: 0,
  },
  dialogPaper: {
    overflowY: 'visible',
  },
}));

type CloseIconStyledType = React.ComponentType<IconButtonProps>;
export const CloseIconStyled: CloseIconStyledType = styled((props) => (
  <IconButton {...props} size='small'>
    <CloseIcon color='inherit' />
  </IconButton>
))(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    right: 10,
    top: 10,
  },
  width: 28,
  height: 28,
  position: 'absolute',
  right: -10,
  top: -10,
  backgroundColor: '#DEDEDE',
  color: theme.palette.grey[500],
  '& > .MuiSvgIcon-root': {
    color: '#000',
  },
  ':hover': {
    backgroundColor: theme.palette.grey[400],
  },
}));

const DialogSignUp = ({ open, closeHandler }: DialogSignUpProps) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const handleSubmit = async (values: SignupDto) => {
    //@ts-ignore
    let affiliate = {};
    //@ts-ignore
    if (window?.PostAffTracker) {
      //@ts-ignore
      //@ts-ignore
      const affTracker = await window?.PostAffTracker?.getAffInfo();

      //@ts-ignore
      const papAccountId = window?.PostAffTracker?._getAccountId() || 'wedaf';
      //@ts-ignore
      const papVisitorId = window?.PostAffTracker?._cmanager?.getVisitorId();

      const papAAid = affTracker?.getAffiliateId() || (window as any)?.localStorage?.getItem('a_aid');
      const papACid = affTracker?.getCampaignId() || (window as any)?.localStorage?.getItem('a_cid');

      const data1 = (window as any)?.localStorage?.getItem('data1');
      const data2 = (window as any)?.localStorage?.getItem('data2');
      const papABid = (window as any)?.localStorage?.getItem('a_bid');

      const source = (window as any)?.localStorage?.getItem('source');
      const clickid = (window as any)?.localStorage?.getItem('clickid');

      if (source === 'affise' && clickid) {
        affiliate = {
          cpaName: 'affise',
          affiseClickId: clickid,
        }
      } else if (papAccountId && papVisitorId && papAAid) {
        const data1Obj = data1 ? { papData1: data1 } : {};
        const data2Obj = data2 ? { papData2: data2 } : {};
        const papABidObj = papABid ? { papABid } : {};
        const papACidObj = papACid ? { papACid } : {};

        affiliate = {
          cpaName: 'postaffiliatepro',
          papAccountId,
          papVisitorId,
          papAAid,
          ...papACidObj,
          ...data1Obj,
          ...data2Obj,
          ...papABidObj,
        };
      }
    }
    dispatch(signUpRequest({ ...values, ...affiliate, ip: await getIP(), navigate }));
  };

  const getIP = useCallback(async () => {
    await fetchDataWithTimeout('https://api.ipify.org?format=json', 3000, { body: '0.0.0.0' })
      .then(async (result) => {
        return result;
      })
      .catch((error) => {
        console.error('Error:', error.body);
        return '0.0.0.0';
      });
    return '0.0.0.0';
  }, []);

  return (
    <Dialog
      classes={{
        root: classes.dialogPaper,
      }}
      TransitionComponent={Zoom}
      open={open}
      onClose={() => closeHandler()}
    >
      <div className={classes.container}>
        <CloseIconStyled onClick={closeHandler} />
        <SignUpLayout onSubmit={handleSubmit} />
      </div>
    </Dialog>
  );
};

export default DialogSignUp;
