import { Breakpoint } from "@mui/material";
import useAppBreakpoints from "./useAppBreakpoints";

const useContainerWidth = (): Breakpoint => {
  const { lg, sm, lgAndUp } = useAppBreakpoints();

  let width: Breakpoint = "xl";
  if (!lg) width = "md";
  if (sm) width = "sm-";
  if (lgAndUp) width = "xl";

  return width;
};

export default useContainerWidth;
