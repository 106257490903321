const TOKEN_KEY: string | null = "access_token";
const AUDIO_KEY: string | null = "audio_key";

const StorageService = {
  getToken(): string | null {
    return localStorage.getItem(TOKEN_KEY);
  },

  setToken(accessToken: string): void {
    return localStorage.setItem(TOKEN_KEY, accessToken);
  },

  removeToken(): void {
    return localStorage.removeItem(TOKEN_KEY);
  },

  getAudio(): string | null {
    return localStorage.getItem(AUDIO_KEY);
  },

  setAudio(): void {
    return localStorage.setItem(AUDIO_KEY, 'true');
  },

  removeAudio(): void {
    return localStorage.removeItem(AUDIO_KEY);
  },

};

export { StorageService };
