import { Button, Container, FormControl, FormControlLabel, RadioGroup } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { ReactComponent as MailIcon } from '@/assets/icons/white-mail.svg';
import { ReactComponent as PinIcon } from '@/assets/icons/white-pin.svg';
import { ReactComponent as PhoneIcon } from '@/assets/icons/phone-white.svg';
import { ReactComponent as SupportIcon } from '@/assets/icons/support-white.svg';
import Business from '@mui/icons-material/Business';
import { Formik } from 'formik';
import { contactUsSchema } from '@/utils/validationSchemas';
import BaseTextField from '../base/text-field.component';
import BaseTextArea from '../base/base-textarea.component';
import BaseButton from '../base/button.component';
import clsx from 'clsx';
import ReCAPTCHA from 'react-google-recaptcha';
import { useCallback, useState } from 'react';
import ContactUsService from '@/services/contact-us.service';
import { useAppSelector } from '@/app/hooks';
import { ISettings } from '@/types/user-profile.types';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: 'calc(100% - 56px)',
    padding: '28px',
    marginTop: '-12px',
    zIndex: '90',
    background: '#FFFFFF',
    borderRadius: '10px 10px 10px 10px',
    boxShadow: '0px 0px 20px rgb(0 0 0 / 5%)',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  headTitle: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '26px',
    lineHeight: '26px',
    width: '100%',
  },
  paragraphFirst: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#000000',
  },
  secondParagraph: {
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
    color: '#808080',
    paddingTop: '24px',
  },
  wrapperLines: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  supportEmail: {
    width: '270px',
    maxHeight: '230px',
    background: '#503EB6',
    borderRadius: '10px',
    padding: '29px',
    marginLeft: '40px',
    [theme.breakpoints.down('md')]: {
      width: 'calc(100% - 40px)',
      marginLeft: 0,
      margin: '20px auto',
    },
  },
  bigLine: {
    fontSize: '54px',
    lineHeight: '64px',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: '#000000',
  },
  iconWrapper: {
    paddingRight: '12px',
    '& svg': {
      width: '20px',
      height: '20px',
    },
  },
  secondBigLine: {
    fontWeight: '500',
    fontSize: '34px',
    lineHeight: '44px',
    textAlign: 'center',
    color: '#808080',
  },
  titleContainer: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  lineWrapper: {
    display: 'flex',
    paddingTop: '20px',
    color: 'white',
    '&:first-child': {
      paddingTop: '0',
    },
  },
  breakline: {
    background: '#E7E6E6',
    height: '1px',
    width: '100%',
    marginBottom: '10px',
    marginTop: '40px',
  },
  formContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  formTextFieldsContainer: {
    marginTop: 20,
    marginBottom: 20,
    width: '100%',
    '& > $formTextField:not(:first-child)': {
      marginTop: 20,
    },
  },
  formTextField: {
    width: '100%',
  },
  formActions: {
    marginTop: 30,
    width: '100%',
  },
  formContactUsBtn: {
    width: '146px',
  },
  formControl: {
    width: '100%',
  },
  formMessageField: {
    height: '132px',
    width: 'calc(100% - 20px)',
  },
}));

const ContactUs = () => {
  const classes = useStyles();
  const { companyAddress, companyName, companyPhone, supportEmail, liveChat } = useAppSelector<ISettings>(
    (state) => state.users.settings
  );

  const onSubmit = async (values: any) => {
    if (captchaValue) {
      await ContactUsService.sendContactUs(values);
    }
  };

  const [captchaValue, setCaptchaValue] = useState(null);

  const onChange = (value: any) => {
    setCaptchaValue(value);
  };

  const openLiveChat = useCallback(
    () => ((window as any).LiveChatWidget as any) && ((window as any).LiveChatWidget as any).call('maximize'),
    [(window as any).LiveChatWidget]
  );

  return (
    <Container maxWidth={'xl'}>
      <div className={classes.container}>
        <div className={classes.titleContainer}>
          <div className={classes.wrapperLines}>
            <div className={classes.headTitle}>Contact us </div>
            <Formik
              initialValues={{
                email: '',
                subject: '',
                message: '',
              }}
              validationSchema={contactUsSchema}
              onSubmit={({ ...values }, { setSubmitting, resetForm }) => {
                onSubmit(values);
                resetForm();
              }}
            >
              {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                <form onSubmit={handleSubmit} className={classes.formContainer}>
                  <FormControl className={classes.formControl}>
                    <div className={classes.formTextFieldsContainer}>
                      <BaseTextField
                        label='email'
                        onChange={handleChange}
                        type='text'
                        placeholder={'email'}
                        baseClass={classes.formTextField}
                      />
                      <BaseTextField
                        label='subject'
                        onChange={handleChange}
                        type='text'
                        placeholder={'subject'}
                        baseClass={classes.formTextField}
                      />
                      <BaseTextArea
                        label='message'
                        onChange={handleChange}
                        placeholder={'Message'}
                        className={clsx(classes.formTextField, classes.formMessageField)}
                      />
                    </div>
                    <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY} onChange={onChange} />
                    {captchaValue && (
                      <div className={classes.formActions}>
                        <BaseButton
                          loading={false}
                          type='submit'
                          color='primary'
                          text='Send'
                          className={classes.formContactUsBtn}
                        />
                      </div>
                    )}
                  </FormControl>
                </form>
              )}
            </Formik>
          </div>
          <div className={classes.supportEmail}>
            <div className={classes.lineWrapper}>
              <div className={classes.iconWrapper}>
                <MailIcon />
              </div>
              <div>{supportEmail}</div>
            </div>
            <div className={classes.lineWrapper}>
              <div className={classes.iconWrapper}>
                <PinIcon />
              </div>
              <div>{companyAddress}</div>
            </div>
            <div className={classes.lineWrapper}>
              <div className={classes.iconWrapper}>
                <PhoneIcon />
              </div>
              <div>{companyPhone}</div>
            </div>
            <div className={classes.lineWrapper}>
              <div className={classes.iconWrapper}>
                <Business />
              </div>
              <div>{companyName}</div>
            </div>
            {liveChat && (
              <div className={classes.lineWrapper} onClick={openLiveChat} style={{ cursor: 'pointer' }}>
                <div className={classes.iconWrapper}>
                  <SupportIcon />
                </div>
                <div>Live Support</div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default ContactUs;
