import { useCallback, useState } from "react";

export type SwitchOn = () => void;
export type SwitchOff = () => void;
export type Toggle = () => void;

export type BinarySwitcherState = (
  initialValue?: boolean
) => [boolean, SwitchOn, SwitchOff, Toggle];

export const useBinarySwitcher: BinarySwitcherState = (
  initialValue = false
) => {
  const [value, setValue] = useState(initialValue);
  const switchOn = useCallback(() => setValue(true), []);
  const switchOff = useCallback(() => setValue(false), []);
  const toggle = useCallback(() => setValue((v) => !v), []);
  return [value, switchOn, switchOff, toggle];
};
