import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React from "react";

type BaseCardProps = {
  children: React.ReactNode;
  cardOverrideStyle?: string;
  wrapperOverrideStyle?: {};
};

const useStyles = makeStyles((theme: Theme) => ({
  generalInfoWrapper: {
    width: '100%',
    height: '100%',
    display: "flex",

  },
  generaInfoCard: {
    width: "100%",
    display: "flex",
    boxSizing: 'border-box',
  },
  contentWrapper: {
    zIndex: 1000,
    backgroundColor: "#fff",
    borderRadius: 10,
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.05)",

    flexBasis: 'calc((100% - 42px) / 2)',
    marginBottom: '32px',
    justifyContent: 'center',

    height: 214,

    '&:nth-child(2n+1)': {
      marginRight: '42px'
    },

    '&.block:last-child': {
      flexBasis: '100%',
    },

    [theme.breakpoints.down("md")]: {
      marginRight: 0,
      flexBasis: 'auto',
      marginBottom: '10px',

      '&:nth-child(2n+1)': {
        marginRight: 0
      },
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: '8px',
      height: 202,
    },
  },
}));

const CreditCard = ({
  children,
  cardOverrideStyle = "",
  wrapperOverrideStyle = {},
}: BaseCardProps) => {
  const classes = useStyles();

  return (
    <div
      style={wrapperOverrideStyle}
      className={clsx(
        classes.contentWrapper,
      )}
    >
      <div className={classes.generalInfoWrapper}>
        <div className={clsx(classes.generaInfoCard, cardOverrideStyle)}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default CreditCard;
