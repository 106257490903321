import React from "react";
import { TableCell, TableRow, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactComponent as OrdersArrow } from "@/assets/icons/OrdersArrow.svg";
import { ReactComponent as OrdersArrowReversed } from "@/assets/icons/OrdersArrowReversed.svg";


const useStyles = makeStyles((theme: Theme) => ({
  expandedRow: {
    backgroundColor: '#F9F9FD'
  },
  collapsedRow: {
    backgroundColor: '#FFFFFF'
  },
  infoBlock: {
    width: '100%'
  },
  list: {
    listStyle: 'none',
    paddingLeft: 0,
    margin: 0
  },
  value: {
    color: '#808080'
  },
  arrow: {
    padding: '20px 0 !important',

    '&>svg': {
      marginLeft: 12
    }
  }
}));

export const ExpandableTableRow = ({ children, expandComponent, ...otherProps }) => {
  const classes = useStyles();

  const [isExpanded, setIsExpanded] = React.useState(false);

  return (
    <>
      <TableRow {...otherProps} className={isExpanded ? classes.expandedRow : classes.collapsedRow}>
        {children}
        <TableCell padding="checkbox" className={classes.arrow}>
          {isExpanded ? <OrdersArrow style={{ width: 12, height: 8 }} onClick={() => setIsExpanded(!isExpanded)} /> : <OrdersArrowReversed style={{ width: 12, height: 8 }} onClick={() => setIsExpanded(!isExpanded)} />}

        </TableCell>
      </TableRow>
      {isExpanded && (
        <TableRow className={classes.infoBlock} >
          <TableCell padding="checkbox" colSpan={3}>
            <ul className={classes.list}>
              {Object.entries(expandComponent).map(([key, value]) => (
                <li>
                  <b>{key}: </b>
                  <span className={classes.value}>{value}</span>
                </li>
              ))}
            </ul>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};