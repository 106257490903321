import { ReactComponent as ArrowIcon } from "@/assets/icons/option-arrow.svg";
import { useFocus, useFormikField } from "@/hooks";
import { Autocomplete, Box, TextField, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import React, { useMemo } from "react";

type SelectFieldProps = {
  list: Array<any>;
  label: string;
  placeholder?: string | undefined;
  className?: string | undefined;
  onChnage: (val: number | string) => void;
  disabled?: boolean | undefined;
  controlledValue?: any;
};

const useStyles = makeStyles((theme: Theme) => ({
  base: {
    display: "flex",
    alignItems: "center",
    borderRadius: 50,
    border: "1px solid rgba(0, 0, 0, 0.1)",
    padding: "0 10px",
    fontSize: 14,
    backgroundColor: "#F9F8FD",
    overflow: "hidden",
  },
  focused: {
    borderColor: theme.palette.radioSelected.main,
    boxShadow: `rgb(255 255 255) 0px 0px 0px 0px, ${theme.palette.radioSelected.main} 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px`,
  },
}));

const SelectField = ({
  list,
  label,
  onChnage,
  placeholder,
  className = "",
  disabled = false,
  controlledValue
}: SelectFieldProps) => {
  const classes = useStyles();
  const [field] = useFormikField({ label });
  const { onFocus, onBlur, isFocused } = useFocus(field.onBlur);

  const baseValue = useMemo(() => {
    return list.find((item) => {
      if (item.value && field.value && placeholder)
        return item.value === field.value[placeholder?.toLowerCase()];
    });
  }, [field, list]);

  return (
    <Autocomplete
      disabled={disabled}
      defaultValue={controlledValue ? null : {
        name: placeholder,
        value: -1,
      }}
      value={controlledValue || baseValue}
      options={list}
      autoHighlight
      onInputChange={(e, value) => {
        if (controlledValue && !e) return;
        onChnage(value)
      }}
      getOptionDisabled={(option) => option.disabled}
      popupIcon={<ArrowIcon style={{ width: 18, height: 18 }} />}
      className={clsx(classes.base, isFocused && classes.focused, className)}
      disableClearable
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => {
        return (
          <Box component="li" {...props}>
            <span>{option.name}</span>
          </Box>
        );
      }}
      renderInput={(params) => {
        return (
          <TextField
            type="button"
            onBlur={onBlur}
            onFocus={onFocus}
            variant="filled"
            placeholder={label}
            {...params}
          />
        );
      }}
    />
  );
};

export default SelectField;
