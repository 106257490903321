import { MenuItemTypes } from "@/types/menu-item.types";

const headerMenu: MenuItemTypes[] = [
  {
    path: "/about",
    name: "About",
    icon: null,
  },
  {
    path: "/faq",
    name: "F.A.Q.",
    icon: null,
  },
  {
    path: "/contacts",
    name: "Contact Us",
    icon: null,
  },
];

export default headerMenu;
