import store, { persistor } from "@/app/store";
import theme from "@/utils/theme";
import { ThemeProvider } from "@mui/material";
import React from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";

type AppBootstrapProps = {
  children: React.ReactNode;
};

const AppBootstrap = ({ children }: AppBootstrapProps) => {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {children}
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
};

export default AppBootstrap;
