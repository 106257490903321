import useFormikField from "@/hooks/useFormikField";
import { FormHelperText, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

type BaseTextFieldProps = {
  onChange: (val: string) => void;
  baseClass: string;
  label: string;
  type: "email" | "password" | "text" | "telephone";
  isFormikField?: boolean;
  placeholder?: string;
};

export const useStyles = makeStyles((theme: Theme) => ({
  label: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    backgroundColor: "#F9F8FD",
    overflow: "hidden",
    width: "100%",
    fontSize: 14,
    borderRadius: 50,
  },
  input: {
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: 50,
    padding: "0 16px",
    lineHeight: "40px",
    fontFamily: "Readex Pro",
    fontSize: 14,
    fontWeight: 500,
    flex: "1 1 auto",
    background: "transparent",

    "&::placeholder": {
      textTransform: "capitalize",
    },
  },
}));

const BaseTextField = ({
  baseClass,
  label,
  onChange,
  isFormikField = true,
  placeholder,
  ...props
}: BaseTextFieldProps) => {
  const classes = useStyles();
  const [field, meta, errorText] = useFormikField({ label });

  return (
    <div className={baseClass}>
      <label className={[classes.label].join(" ")}>
        <input
          className={classes.input}
          placeholder={placeholder}
          {...props}
          {...field}
        />
      </label>
      {errorText && <FormHelperText error={true}>{errorText}</FormHelperText>}
    </div>
  );
};

export default BaseTextField;
