import React from "react";
import { ReactComponent as CloseIcon } from "@/assets/icons/drawer-icons/close.svg";
import { IconButton } from "@mui/material";

type DrawerTopProps = {
  toggleDrawer: (event: any) => void;
};

const DrawerTop = ({ toggleDrawer }: DrawerTopProps) => {
  return (
    <div style={{ padding: "19px 30px" }}>
      <IconButton onClick={toggleDrawer}>
        <CloseIcon />
      </IconButton>
    </div>
  );
};

export default DrawerTop;
